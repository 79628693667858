(function () {
  polyfill();

  function polyfill() {
    var $body = $('body');

    if (!Modernizr.objectfit) {

      $body.find('.js-object-fit').each(function (index, elem) {
        var $background = $(elem);
        var classes = 'c-' + $background.data('component') + '__background';

        $background.replaceWith('<div class="' + classes + '" style="background-image: url(' + $background.attr('src') + ');"/>');
      });
    }
  }
})();
